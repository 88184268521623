<template>
  <div class="panel">
    <div class="panel-heading">
      <div class="is-flex is-flex-direction-row">
        <img :src="source.icon" :alt="source.service" class="service-icon" />
        <h3>{{ source.service }}</h3>
      </div>
    </div>
    <div  v-for="(sourceItem, i) in source.sources" :key="i">
      <div class="header" v-if="sourceItem.source === 'Lookup'">Lookup</div>
      <div class="header" v-if="sourceItem.source ==='Smsc_ru'">SMSC.ru</div>
      <template  v-if="typeof sourceItem.stats !== 'undefined'">
        <div v-for="(stat, j) in statsKeys[i]" :key="j">
          <p class="panel-block" v-if="!['currency', 'balance'].includes(stat)">
            {{ stat | paramName  }}: {{ sourceItem.stats[stat] }}
          </p>
          <p class="panel-block" v-if="stat === 'balance'">
            {{ stat | paramName  }}: {{ sourceItem.stats[stat] }}
            <span v-if="statsKeys[i].includes('currency')" style="margin-left: 5px;"> {{ sourceItem.stats['currency'] }}</span>
          </p>
        </div>
      </template>
      <hr style="border-top: 4px solid #ededed; margin-top: 0; margin-bottom: 0;"
          v-if="i < source.sources.length-1">
    </div>
  </div>
</template>

<script>
import filters from "@/mixins/filters";
export default {
  name: "Key",
  props: ['source'],
  mixins: [filters],
  computed: {
    statsKeys() {
      let keys = [];
      for(let source of this.source.sources) {
        if(typeof source.stats !== 'undefined') {
          keys.push(Object.keys(source.stats));
        } else {
          keys.push([]);
        }
      }
      return keys;
    }
  }
}
</script>

<style lang="scss">/*
.service-icon {
  width: 20px;
  margin-right: 15px;
}
h3 {
  font-size: 1.4em;
  font-weight: 700;
}
.header{
  padding: 0.5em 0.75em;
  font-weight: 600;
}
*/</style>