<template>
<div class="block">
  <key v-for="(key, index) in keys" :key="index" :source="key"></key>
</div>
</template>

<script>
import {mapState} from 'vuex';
import Key from "@/components/Key";
export default {
  name: "Keys",
  components: {
    Key
  },
  computed: {
    ...mapState({
      keys: state => state.ui.keys
    })
  },
  mounted() {
    this.$store.dispatch('ui/getKeys');
  }
}
</script>

<style scoped>

</style>